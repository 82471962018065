.control:before {
  content: url("Assets/Icons/monocolored/checkboxEmpty.svg");
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}
:checked + .control:before {
  content: url("Assets/Icons/monocolored/checkboxTicked.svg");
}
.checkboxInput {
  display: none;
}

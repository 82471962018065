.submission-label {
  @apply text-transparent;
}

.submission-label path[stroke="#fff"] {
  stroke: transparent;
}

input[type="checkbox"]:checked + .submission-label {
  @apply text-green;
}

input[type="checkbox"]:checked + .submission-label path[stroke="#fff"] {
  stroke: white;
}

.modal {
  animation: modalSlide 0.3s ease-out;
}

@keyframes modalSlide {
  from {
    transform: translate(0, 80%);

    opacity: 0;
  }

  to {
    transform: translate(0, 0);

    opacity: 1;
  }
}

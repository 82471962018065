.registrationModal {
  @apply lg:flex w-screen lg:w-auto min-w-full flex flex-col lg:flex-row relative;
}

@media only screen and (min-width: 768px) {
  .registrationModal {
    @apply flex;
    width: 80vw;
    min-height: 50vh;
  }
}

@media only screen and (min-width: 1268px) {
  .registrationModal {
    @apply flex;
    width: 70vw;
    min-height: 450px;
  }
}

.overlay {
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  inset: 0;
}

.drawer {
  animation: drawerSlide 250ms ease-out forwards;
}

@keyframes drawerSlide {
  from {
    transform: translate(100%, 0%);
    opacity: 0;
  }

  to {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}

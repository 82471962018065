@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --error-color: #ff4646;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  src: local("Nunito-Light"), url("./Assets/Fonts/Nunito-Light.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 300;
  src: local("Nunito-LightItalic"), url("./Assets/Fonts/Nunito-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: normal;
  src: local("Nunito-Regular"), url("./Assets/Fonts/Nunito-Regular.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: normal;
  src: local("Nunito-Italic"), url("./Assets/Fonts/Nunito-Italic.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  src: local("Nunito-SemiBold"), url("./Assets/Fonts/Nunito-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  src: local("Nunito-SemiBoldItalic"),
    url("./Assets/Fonts/Nunito-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: local("Nunito-Bold"), url("./Assets/Fonts/Nunito-Bold.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 700;
  src: local("Nunito-BoldItalic"), url("./Assets/Fonts/Nunito-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  src: local("Nunito-ExtraBold"), url("./Assets/Fonts/Nunito-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 800;
  src: local("Nunito-ExtraBoldItalic"),
    url("./Assets/Fonts/Nunito-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  src: local("Nunito-Black"), url("./Assets/Fonts/Nunito-Black.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 900;
  src: local("Nunito-Black"), url("./Assets/Fonts/Nunito-BlackItalic.woff") format("woff");
}

#root {
  white-space: pre-line;
}

html {
  background-color: #fafafa;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Nunito, source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  outline: 0 !important;
}
*:focus {
  outline: 0 !important;
}

a:visited a:active {
  outline: 0;
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.pageContainer {
  @apply flex flex-col pt-10 items-center px-4 w-full lg:w-1/2 xl:w-2/3;
}

.input {
  @apply focus-within:border-blue-100 hover:shadow-blue focus-within:shadow-blue transition-shadow;
}

.input-error {
  @apply border border-red-100 hover:shadow-red focus-within:border-red-100 shadow-red transition-shadow;
}

.add-file__modal-body {
  @apply max-h-70vh hsm:max-h-80vh hmd:max-h-90vh;
}

.add-file-form {
  @apply flex flex-col overflow-y-auto px-1 sm:pb-0 flex-1;
}

.add-file-form__content {
  @apply flex-1 overflow-y-auto px-1 -mx-1;
}

.add-file__submit-button {
  @apply self-center w-full sm:w-1/3 mt-6;
}

.tile-border {
  @apply border border-grey-200 shadow-sm hover:shadow-blue transition-shadow;
}

.tile-border__dragging {
  @apply border-l-4 border-quartz-600;
}

.drop-shadow-dropdown {
  filter: drop-shadow(2px 7px 12px rgba(0, 0, 0, 0.08));
}

::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: #e5e0eb;
  border-radius: 0.2rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.2rem;
  background-color: #4782ef;
}

::-webkit-scrollbar-button {
  display: none;
}

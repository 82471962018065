.react-datepicker-popper {
  width: 92vw;
}

@media screen and (min-width: 500px) {
  .react-datepicker-popper {
    width: fit-content;
  }
}

.react-datepicker {
  border: none;
  font-size: 1rem;
  font-family: Nunito;
  width: 100%;

  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 500px) {
  .react-datepicker {
    min-width: 22rem;
    flex-direction: row;
  }
}

.react-datepicker__month-container {
  flex: 1;
  order: 1;
}

.react-datepicker__time-container {
  display: none;
  flex: 1;
  order: 2;
  margin-left: 1rem;
  border: none;
  padding-top: 38px;
  min-width: 7rem;
  overflow: hidden;
}

@media screen and (min-width: 500px) {
  .react-datepicker__time-container {
    display: block;
  }
}

.react-datepicker__time {
  width: 100%;
  margin: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.react-datepicker__time-box {
  width: 100% !important;
  margin: 0;
}

.react-datepicker__input-time-container {
  margin: 0;
  margin-left: -0.5rem;
  order: 0;
}

@media screen and (min-width: 500px) {
  .react-datepicker__input-time-container {
    display: none;
  }
}

.react-datepicker-time__caption {
  display: none !important;
}

.react-datepicker__time-list-item--selected {
  background-color: #4782ef !important;
  border-radius: 0.25rem;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__week {
  display: flex;
  gap: 0.5rem;
}

.react-datepicker__day {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 1rem;
  height: 2rem;
  font-weight: 600;
}

.react-datepicker__day--selected {
  background-color: #4782ef;
}

.react-datepicker__header {
  background-color: transparent;
  border-color: #ebeff1;
  margin-bottom: 0.5rem;
}

.react-datepicker__day-names {
  background-color: transparent;
  display: flex;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
}

.react-datepicker__day-name {
  color: #90979b;
  flex: 1;
}

.react-datepicker__day--outside-month {
  color: #b9c2c7;
}

.react-datepicker__day--disabled {
  cursor: not-allowed !important;
  color: #f5f7f8;
}

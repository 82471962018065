input[type="checkbox"].eolas-toggle-input {
  appearance: none;
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
}

input[type="checkbox"].eolas-toggle-input::after {
  content: "";
  position: absolute;
  transition: all 200ms ease-in-out;
  background-color: #b9c2c7;

  display: block;
  border-radius: 0.5rem;
  height: 1rem;
  width: 1rem;

  left: 0.125rem;
}

input[type="checkbox"].eolas-toggle-input:checked::after {
  background-color: #3374ed;
  left: calc(2.5rem - 1.25rem);
}
